<template>
  <div>
    <div class="grid table-header">
      <span/>
      <span class="table-column-header">{{ $t('components.webpages.abk_webpages_tree.publication_status') }}</span>
      <span class="table-column-header">{{ $t('components.webpages.abk_webpages_tree.show_in_menu') }}</span>
      <div class="d-flex mx-auto date table-column-header">
        {{ $t('components.webpages.abk_webpages_tree.edition_date') }}
        <pcg-sort-btn v-if="!draggable"
                      :value="{ orderBy: 'published_at', orderDirection: 'asc' }"
                      class="ml-2 sort-button"
                      @input="$emit('sort', $event)"/>
      </div>
      <div class="d-flex mx-auto date table-column-header">
        {{ $t('components.webpages.abk_webpages_tree.creation_date') }}
        <pcg-sort-btn v-if="!draggable"
                      class="ml-2 sort-button"
                      :value="{ orderBy: 'created_at', orderDirection: 'asc' }"
                      @input="$emit('sort', $event)"/>
      </div>
      <span/>
    </div>
    <draggable-tree :key="tableKey"
                    :data="pages"
                    :draggable="draggable"
                    :indent="25"
                    :space="5"
                    :ondragend="ondragend"
                    @change="$emit('change', $event)">
      <template v-slot="{data}">
        <abk-webpages-tree-node v-if="!data.isDragPlaceHolder"
                                :class="{ draggable: draggable }"
                                :page="data"
                                @publishPage="$emit('publishPage', $event)"
                                @showInMenu="$emit('showInMenu', $event)"
                                @deletePage="$emit('deletePage', $event)"
                                :links-active="!draggable"/>
      </template>
    </draggable-tree>
  </div>
</template>

<script>
import AbkWebpagesTreeNode from './AbkWebpagesTreeNode'
import { DraggableTree } from 'vue-draggable-nested-tree'
export default {
  name: 'AbkWebpagesTree',
  components: { AbkWebpagesTreeNode, DraggableTree },
  props: {
    tableKey: String,
    pages: Array,
    draggable: Boolean,
    ondragend: Function
  }
}
</script>

<style scoped lang="scss">
  @import "app/javascript/abk/assets/stylesheets/vars";

  .draggable {
    cursor: pointer;
    padding-left: 0;
  }
  /deep/ .draggable-placeholder-inner {
    position: relative;
    border: 1px dashed $main-color;
    box-sizing: border-box;
    color: $main-color;
    text-align: center;
    padding: 0;
    display: flex;
    align-items: center;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      background: $main-active-color;
      opacity: .3;
    }
  }
  .table-column-header {
    white-space: nowrap;
    font-size: $font-size-s;
    line-height: 1;
    color: $pcg-gray;
    font-weight: 500;
    padding: $pcg-m-sm $pcg-m-lg $pcg-m-sm 0;
  }
  .table-header {
    margin-bottom: $pcg-m-sm;
  }
  /deep/ .grid {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 9vw 9vw 9vw 9vw 7vw;
    & > * {
      padding-left: $pcg-m-xs;
      padding-right: $pcg-m-xs;
      &:not(:first-child) {
        text-align: center;
      }
    }
  }
  @media(max-width: $screen-md-max) {
    /deep/ .date {
      display: none!important;
    }
    /deep/ .grid {
      grid-template-columns: 1fr 15vw 18vw 7vw;
    }
  }
  @media(max-width: $screen-sm-max) {
    /deep/ .grid {
      grid-template-columns: 1fr 20vw 20vw 10vw;
    }
  }
</style>

<style scoped lang="scss">
  @import "app/javascript/abk/assets/stylesheets/vars-contrast";
  .contrast{
    /deep/ .draggable-placeholder-inner {
      border: 1px dashed $main-color;
      color: $main-color;
      &:before {
        background: $main-active-color;
      }
    }
    .sort-button {
      color: $pcg-gray;
      /deep/ .bootstrap-select .dropdown-toggle {
        color: $main-color !important;
        border: none;
      }
    }
  }
</style>
